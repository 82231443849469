<template>
    <div class="service-grid-item service-grid-item--style2">
        <div class="service-grid-item__image">
            <div class="service-grid-item__image-wrapper">
                <router-link :title="project.title" @click.native="scrollToTop" :to="`/renovation-mande-project-details/${ project.id }`">
                    <img :src="project.image" class="img-fluid" alt="thumbnail">
                </router-link>
            </div>

        </div>
        <div class="service-grid-item__content">
            <h3 class="title">
                <router-link :title="project.title" class="navigation-item__link_text" @click.native="scrollToTop" :to="`/renovation-mande-project-details/${ project.id }`">
                    {{ project.title }}
                </router-link>
            </h3>
            <p class="subtitle navigation-item__link_text">{{ project.desc }}</p>
            <router-link @click.native="scrollToTop" :to="`/renovation-mande-project-details/${ project.id }`" class="see-more-link">VIEW MORE</router-link>
        </div>
    </div>
    
</template>

<script>
export default {
    props: ['project'],
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0)
        }
    }    
};
</script>

<style lang="scss">
.navigation-item__link_text {
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>